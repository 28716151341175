module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-62449582-7","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KNZWKSP","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"es","configPath":"/home/runner/work/landing-page/landing-page/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/locales","i18nextOptions":{"ns":["home","cookies","nav","footer","newIndex","starter","indexV3"],"defaultNS":"home"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/landing-page/landing-page/src/layouts/index.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://trato.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
