// /* global fbq */
import config from './src/config';
// exports.onRouteUpdate = function onRouteUpdate({ location }) {
//   // Don't track while developing.
//   if (process.env.NODE_ENV === 'production' && typeof fbq === 'function') {
//     fbq('track', 'ViewContent');
//   }
// };'

export const onRouteUpdate = ({ location }) => {
  let playbookId = config.pipedriveLeadbooster[config.defaultLang];
  
  const [, locale] = location.pathname.split('/');
  
  const localePlaybookId = config.pipedriveLeadbooster[locale];
  if (localePlaybookId) {
    playbookId = localePlaybookId; // s
  }

  window.pipedriveLeadboosterConfig = {
    base: 'leadbooster-chat.pipedrive.com',
    companyId: 5662609,
    playbookUuid: playbookId,
    version: 2,
  };
}

export const onInitialClientRender = () => {
  localStorage.removeItem('country-code');
}