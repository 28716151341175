// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abogados-js": () => import("./../../../src/pages/abogados.js" /* webpackChunkName: "component---src-pages-abogados-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-firma-electronica-js": () => import("./../../../src/pages/firma-electronica.js" /* webpackChunkName: "component---src-pages-firma-electronica-js" */),
  "component---src-pages-firma-electronica-vs-avanzada-js": () => import("./../../../src/pages/firma-electronica-vs-avanzada.js" /* webpackChunkName: "component---src-pages-firma-electronica-vs-avanzada-js" */),
  "component---src-pages-freelance-js": () => import("./../../../src/pages/freelance.js" /* webpackChunkName: "component---src-pages-freelance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leads-js": () => import("./../../../src/pages/leads.js" /* webpackChunkName: "component---src-pages-leads-js" */),
  "component---src-pages-legal-advisor-js": () => import("./../../../src/pages/legal-advisor.js" /* webpackChunkName: "component---src-pages-legal-advisor-js" */),
  "component---src-pages-oyster-js": () => import("./../../../src/pages/oyster.js" /* webpackChunkName: "component---src-pages-oyster-js" */),
  "component---src-pages-sello-ambiental-js": () => import("./../../../src/pages/sello-ambiental.js" /* webpackChunkName: "component---src-pages-sello-ambiental-js" */),
  "component---src-pages-slack-integration-js": () => import("./../../../src/pages/slack-integration.js" /* webpackChunkName: "component---src-pages-slack-integration-js" */),
  "component---src-pages-starter-js": () => import("./../../../src/pages/starter.js" /* webpackChunkName: "component---src-pages-starter-js" */),
  "component---src-pages-templatedetail-js": () => import("./../../../src/pages/templatedetail.js" /* webpackChunkName: "component---src-pages-templatedetail-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-trato-clm-js": () => import("./../../../src/pages/trato-clm.js" /* webpackChunkName: "component---src-pages-trato-clm-js" */),
  "component---src-pages-trato-education-2-js": () => import("./../../../src/pages/trato-education-2.js" /* webpackChunkName: "component---src-pages-trato-education-2-js" */),
  "component---src-pages-trato-education-js": () => import("./../../../src/pages/trato-education.js" /* webpackChunkName: "component---src-pages-trato-education-js" */),
  "component---src-pages-trato-partners-js": () => import("./../../../src/pages/trato-partners.js" /* webpackChunkName: "component---src-pages-trato-partners-js" */),
  "component---src-pages-trato-vs-docusign-js": () => import("./../../../src/pages/trato-vs-docusign.js" /* webpackChunkName: "component---src-pages-trato-vs-docusign-js" */),
  "component---src-pages-trato-vs-mifiel-js": () => import("./../../../src/pages/trato-vs-mifiel.js" /* webpackChunkName: "component---src-pages-trato-vs-mifiel-js" */),
  "component---src-pages-zoom-integration-js": () => import("./../../../src/pages/zoom-integration.js" /* webpackChunkName: "component---src-pages-zoom-integration-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-template-details-js": () => import("./../../../src/templates/template-details.js" /* webpackChunkName: "component---src-templates-template-details-js" */)
}

