export default {
  defaultLang: 'es',
  fbPixels: {
    tratoEducationForm: '431496334459533',
    tratoHomeForm: '242746370240614',
  },
  pipedriveLeadbooster: {
    es: 'd45ed4d3-e89c-4be0-b31c-a851eb959c93',
    en: '8edccd24-4e32-4a22-aa1e-3e96ba103b63',
    pt: '8edccd24-4e32-4a22-aa1e-3e96ba103b63',
  },
  leadFeederKey: 'v1_3P1w24dAQvlamY5n',
  metricoolId: 'cdac32a6f946147555fce04be0ba4cd2'
};
